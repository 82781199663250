import axios from 'axios'
import global from 'global'
import store from '@/store'
// import router from '@/router'
import { getToken } from '@/utils/cookie'

/**
 * 可以导出多个实例满足不同的需求
 */

// 创建axios实例
const instance = axios.create({
  baseURL: global.base.api,
  timeout: 60000
})

// request-interceptor
instance.interceptors.request.use(
  config => {
    let token = store.getters.token || window.sessionStorage.getItem('school_token');
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      // 'Accept': 'application/json',
      'Authorization': token ? config.headers.Authorization = 'Bearer ' + token : ''
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response-interceptor
instance.interceptors.response.use(
  response => {
    if (response.status === 204) {
      response.data = null
    }
    return response.data
  },
  error => {
    const err = error.response
    if (!err) {
      return
    }
    if (err.status === 401) {
      // do something before 401 reject
      // router.push('/401')
      console.error('您没有登录或登录已超时')
    }
    if (err.status === 406) {
      console.error('您没有权限进行此操作')
      // return Promise.reject(err.data)
    }
    if (err.status === 400 && err.data === 'xss') {
      console.error('输入字符串存在异常字符，请检查确认')
      // return Promise.reject(err.data)
    }
    if (err.config.url == null || err.status !== 401) {
      // return Promise.reject(err.data)
    }

    return Promise.reject(err)
  }
)
export default instance
