import { getOrderLength } from "@/serve/order";

const review = {
  state: {
    hasReview: false,
  },
  mutations: {
    toggleReview: (state, bool) => {
      state.hasReview = bool > 0;
    },
  },
  actions: {
    async checkReview({ state, commit }) {
      let orderParams = {
        shopNeedAudit: true,
        state: "NEW"
      };
      try {
        let orderReview = await getOrderLength(orderParams);
        commit("toggleReview", orderReview);
      } catch (e) {
        console.error(e);
      }
    }
  }
};

export default review;
