// 按需引入antDesign组件
import Vue from 'vue'
import {
  Button,
  Input,
  Form,
  Checkbox,
  Icon,
  message,
  Carousel,
  Cascader,
  InputNumber,
  Table,
  Popconfirm,
  Steps,
  Card,
  Divider,
  Tabs,
  Modal,
  Upload,
  Select,
  Radio,
  Breadcrumb,
  Tag,
  Menu,
  Row,
  Col,
  Popover,
  Pagination,
  Dropdown,
  Empty,
  Tooltip,
  DatePicker,
  Alert,
  Spin,
  FormModel,
  Switch
} from 'ant-design-vue'
Vue.use(FormModel)
Vue.use(Pagination)
Vue.use(Spin)
Vue.use(DatePicker)
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(Checkbox)
Vue.use(Icon)
Vue.use(Carousel)
Vue.use(Cascader)
Vue.use(InputNumber)
Vue.use(Table)
Vue.use(Popconfirm)
Vue.use(Steps)
Vue.use(Card)
Vue.use(Divider)
Vue.use(Tabs)
Vue.use(Modal)
Vue.use(Select)
Vue.use(Upload)
Vue.use(Radio)
Vue.use(Breadcrumb)
Vue.use(Tag)
Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(Empty)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Switch)

Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
