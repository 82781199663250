<template>
<div class="yct-layout-wrapper">
  <header-component></header-component>
  <div class="content-wpr clearfix">
    <side-bar class="content-wpr-left"></side-bar>
    <div class="content-wpr-right">
      <div class="public-wpr-bg">
        <!-- <s-breadcrumb v-if="breadcrumbVisible"></s-breadcrumb> -->
        <router-view :key="$route.name"></router-view>
      </div>
    </div>
  </div>
  <footer-component></footer-component>
</div>
</template>

<script>

import HeaderComponent from './common/header'
import FooterComponent from './common/footer'
import SideBar from './common/siderBar'

export default {
  name: 'YctLayout',
  components: {
    HeaderComponent,
    FooterComponent,
    SideBar
  },
  data () {
    return {
      // title: config.head.title,
      // description: config.head.description,
      // copyright: config.head.copyright,
      // breadcrumbVisible: config.breadcrumb
    }
  },
  mounted () {
    // document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    // document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
.yct-layout-wrapper {
  background-color: #f5f5f5;
  // height: 100%;
  .content-wpr{
    position: relative;
    margin: 20px auto;
    // max-width: 1200px;
    width: 1300px;
  }

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }
  .content-wpr-left{
    width: 180px;
    /* top: 20px;
    position: absolute;
    left: 0; */
    float: left;
    min-height: 697px;
    background-color: #fff;
    padding-bottom: 10px;
  }
  .content-wpr-right{
    float: right;
    width: 1100px;
    min-height: 600px;
  }

  /* .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
    background-size: 100%;
    padding: 110px 0 144px;
    position: relative;

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }

        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, 0.85);
          font-family: Avenir, "Helvetica Neue", Arial, Helvetica, sans-serif;
          font-weight: 600;
          position: relative;
          top: 2px;
        }
      }
      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .main {
      min-width: 260px;
      width: 368px;
      margin: 0 auto;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        margin-bottom: 8px;
        font-size: 14px;
        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
      }
    }
  } */
}
</style>
