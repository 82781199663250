import {
  BasicLayout,
  PageView
} from '@/layouts'
export default [{
  path: '/',
  meta: {
    title: '首页',
    icon: ''
  },
  component: BasicLayout,
  children: [{
    path: '/',
    order: 1,
    name: 'detail',
    hidden: true,
    component: PageView,
    meta: {
      title: '商品录入',
      icon: 'setting'
    },
    children: [{
      path: '/detail/0',
      name: 'detail',
      order: 3,
      component: () => import('@/views/product/detail'),
      meta: {
        title: '商品录入',
        keepAlive: true,
        permission: true,
        activeName: 'detail'
      }
    },
    {
      path: '/detail/:id',
      name: 'detailId',
      hidden: true,
      component: () => import('@/views/product/detail'),
      meta: {
        title: '商品编辑',
        keepAlive: true,
        permission: true,
        activeName: 'detail'
      }
    },
    {
      path: '/preview/:id',
      name: 'preview',
      hidden: true,
      component: () => import('@/views/product/preview'),
      meta: {
        title: '商品编辑',
        keepAlive: true,
        permission: true,
        activeName: 'preview'
      }
    }
    ]
  },
  {
    path: '/',
    order: 2,
    name: 'productList',
    component: PageView,
    meta: {
      title: '商品管理',
      icon: 'setting'
    },
    children: [
      // {
      //   path: '/productList',
      //   name: 'productList',
      //   hidden: true,
      //   component: () => import('@/views/product/lower'),
      //   meta: { title: '商品列表', keepAlive: true, permission: true, activeName: 'productList' }
      // },
     /*  {
        path: '/detail/0',
        name: 'detail',
        order: 3,
        component: () => import('@/views/product/detail'),
        meta: {
          title: '商品录入',
          keepAlive: true,
          permission: true,
          activeName: 'detail'
        }
      }, */
      {
        path: '/goodsList',
        name: 'goodsList',
        order: 3,
        component: () => import('@/views/product/goodsList'),
        meta: {
          title: '商品列表',
          keepAlive: true,
          permission: true,
          activeName: 'goodsList'
        }
      }
    ]
  },
  {
    path: '/',
    order: 3,
    name: 'member',
    component: PageView,
    meta: {
      title: '会员管理',
      icon: 'setting'
    },
    children: [{
      path: '/addVip/0',
      name: 'addVip',
      component: () => import('@/views/member/addVip'),
      meta: {
        title: '会员录入',
        keepAlive: true,
        permission: true,
        activeName: 'addVip'
      }
    },
    {
      path: '/addVip/:id',
      name: 'addVipId',
      hidden: true,
      component: () => import('@/views/member/addVip'),
      meta: {
        title: '会员录入',
        keepAlive: true,
        permission: true,
        activeName: 'addVip'
      }
    },
    {
      path: '/vipList',
      name: 'vipList',
      component: () => import('@/views/member/parMangeList'),
      children: [{
        path: '/teacherList',
        name: 'teacherList',
        component: () => import('@/views/member/teacherList'),
        meta: {
          title: '教师列表',
          keepAlive: true,
          permission: true,
          activeName: 'teacherList'
        }
      },
      {
        path: '/studentList',
        name: 'studentList',
        component: () => import('@/views/member/studentList'),
        meta: {
          title: '学生列表',
          keepAlive: true,
          permission: true,
          activeName: 'studentList'
        }
      }
      ],
      meta: {
        title: '会员列表',
        keepAlive: true,
        permission: true,
        activeName: 'vipList'
      }
    }
    ]
  },
  {
    path: '/',
    order: 4,
    name: 'orders',
    component: PageView,
    meta: {
      title: '订单管理',
      icon: 'setting'
    },
    children: [{
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/index'),
      meta: {
        title: '订单列表',
        keepAlive: true,
        permission: true,
        activeName: 'orders'
      }
    },
    {
      path: '/orderDetail/:id',
      name: 'orderDetail',
      hidden: true,
      component: () => import('@/views/orders/orderDetail'),
      meta: {
        title: '订单详情',
        keepAlive: true,
        permission: true,
        activeName: 'orderDetail'
      }
    },
    {
      path: '/orderState/:id',
      name: 'orderState',
      hidden: true,
      component: () => import('@/views/orders/orderState'),
      meta: {
        title: '订单状态',
        keepAlive: true,
        permission: true,
        activeName: 'orderState'
      }
    }
    ]
  },
 /*  {
    path: '/',
    order: 5,
    name: 'supertube',
    component: PageView,
    meta: {
      title: '超管管理',
      icon: 'setting'
    },
    children: [{
      path: '/supertubeList',
      name: 'supertubeList',
      component: () => import('@/views/member/supertubeList'),
      meta: {
        title: '超管列表',
        keepAlive: true,
        permission: true,
        activeName: 'supertubeList'
      }
    }]
  }, */
 /*  {
    path: '/',
    order: 6,
    name: 'chargeMange',
    component: PageView,
    meta: {
      title: '分管管理',
      icon: 'setting'
    },
    children: [{
      path: '/chargeMangeList',
      name: 'chargeMangeList',
      component: () => import('@/views/member/chargeMangeList'),
      meta: {
        title: '分管列表',
        keepAlive: true,
        permission: true,
        activeName: 'chargeMangeList'
      }
    }]
  }, */
  {
    path: '/',
    order: 7,
    name: 'supplierList',
    component: PageView,
    meta: {
      title: '供应商管理',
      icon: 'setting'
    },
    children: [{
      path: '/supplierList',
      name: 'supplierList',
      component: () => import('@/views/member/supplierList'),
      meta: {
        title: '供应商列表',
        keepAlive: true,
        permission: true,
        activeName: 'supplierList'
      }
    }]
  }
  ]
}]
