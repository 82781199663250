const global = {
  domain: 'lexiangspace.com',
  base: {
    url: '//school.lexiangspace.com/api',
    api: '//school.lexiangspace.com/api'
  },
  file: {
    url: '//school.lexiangspace.com/api',
    uploadUrl: '//api.lexiangspace.com'
  },
  fileTypeMimes: {
    'Attach': '*',
    'Image': 'image/gif,image/jpeg,image/bmp,image/png',
    'Pdf': 'application/pdf',
    'Excel': 'application/vnd.ms-excel',
    'ImgPdf': 'image/gif,image/jpeg,image/bmp,image/png,application/pdf'
  }
}

export default global
