import router from '@/router'

// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { setDocumentTitle } from '@/utils/util'
import config from '@/libs/config'
// import { getToken } from '@/utils/cookie'
// import store from '@/store'
// NProgress.configure({ showSpinner: false }) // NProgress Configuration

// const whiteList = ['login', 'register', 'registerResult'] // 白名单不需要登录
// const whiteStoreList = ['login', 'Exception405', 'guide', 'storeSwitch', 'storeApply']

router.beforeEach((to, from, next) => {
  to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} - ${config.head.title}`))
  let token = window.sessionStorage.getItem('school_token')
  if (token) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({
        path: '/login'
      })
    }
  }
})

router.afterEach(() => {
  // NProgress.done()
})
