import request from '@/utils/request'

const getOrderList = (params) => {
  return request({
    url: '/school/orders',
    params,
    method: 'get'
  })
}

const getOrderDetail = (id) => {
  return request({
    url: `/school/orders/${id}`,
    method: 'get'
  })
}

const getOrderLength = (params) => {
  return request({
    url: `/school/orders/count`,
    params,
    method: 'get'
  })
}
const isAgree = (id, params) => {
  return request({
    url: `/school/orders/${id}/audit`,
    data: params,
    method: 'put'
  })
}
const invoiceInfo = (id, params) => {
  return request({
    url: `/school/orders/${id}/invoice`,
    data: params,
    method: 'put'
  })
}
const isPayment = (id) => {
  return request({
    url: `/school/orders/${id}/payment`,
    data: {
      remark: ''
    },
    method: 'put'
  })
}

/* const cancelOrder = (id, params) => {
  return request({
    url: `/sell/orders/${id}/cancel`,
    data: params,
    method: 'put'
  })
}

const isRefund = (id, params) => {
  return request({
    url: `/sell/orders/${id}/back`,
    data: params,
    method: 'put'
  })
}

 const sendGoods = (id, params) => {
  return request({
    url: `/sell/orders/${id}/delivery`,
    data: params,
    method: 'put'
  })
}

const isPayment = (id) => {
  return request({
    url: `/sell/orders/${id}/payment`,
    data: { remark: '' },
    method: 'put'
  })
} */
const getStoreInfo = (id) => {
  return request({
    url: `/open/stores/${id}`,
    method: 'get'
  })
}
export {
  invoiceInfo,
  isPayment,
  getOrderList,
  getOrderDetail, // 获取订单详情
  isAgree, // 审核订单
  getOrderLength, // 订单数量
  getStoreInfo
}
